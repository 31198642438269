import cn from 'classnames';
// Ui
import MillionsIcon, { IconNames } from 'ui3/MillionsIcon/MillionsIcon';
import Loader from 'ui3/Loader/Loader';
// Styles
import styles from './Button.module.scss';

export type ButtonVariant = 'primary' | 'secondary' | 'success' | 'error';
export type ButtonSize = 'large' | 'medium' | 'small';
export type ButtonIcon = IconNames;

export type BasicButtonProps = {
  loading?: boolean;
  size?: ButtonSize;
  subVariant?: 'outlined' | 'text';
  variant?: ButtonVariant;
  block?: boolean;
  icon?: ButtonIcon;
};

export type ButtonProps = React.ComponentPropsWithoutRef<'button'> &
  BasicButtonProps;

const Button = ({
  children,
  variant = 'primary',
  subVariant,
  size = 'large',
  loading,
  className,
  disabled,
  icon,
  block,
  type = 'button',
  ...rest
}: ButtonProps) => {
  return (
    <button
      className={cn(
        styles.button,
        styles[variant],
        {
          [styles.outline]: subVariant === 'outlined',
          [styles.text]: subVariant === 'text',
          [styles.block]: block,
        },
        styles[size],
        className
      )}
      disabled={disabled || loading}
      type={type}
      {...rest}
    >
      {loading && <Loader loading={loading} size="small" />}

      <span className={styles.buttonContent}>
        {icon && <MillionsIcon name={icon} className={styles.icon} />}
        {children && <>{children}</>}
      </span>
    </button>
  );
};

export default Button;
