export type FileMetadata = {
  ext: string;
  contentType: string;
};

export type CreatePresignedUrlProps = {
  files: File[];
  getPresignedUrls: (fileMetas: FileMetadata[]) => Promise<PresignedUrl[]>;
};

export type PresignedUrl = {
  fields: string;
  url: string;
  key: string;
};

export type PresignedUrlWithFile = PresignedUrl & {
  file: File;
};

export type PresignedUrlResultItem = {
  key: string;
};

const CONTENT_TYPE_TO_EXT = {
  'image/png': 'png',
  'image/jpeg': 'jpeg',
  'video/mpeg': 'mp4',
  // add more here
};

export const deriveExtFromContentType = (
  contentType: string
): string | undefined => {
  return CONTENT_TYPE_TO_EXT[contentType.toLowerCase()];
};

const createPresignedUrls = async ({
  files,
  getPresignedUrls,
}: CreatePresignedUrlProps): Promise<PresignedUrlWithFile[]> => {
  const fileMetas: FileMetadata[] = files.map((file) => {
    const imageExtFromFileNameParts = file.name.split('.');
    const ext =
      imageExtFromFileNameParts.length > 1
        ? imageExtFromFileNameParts[imageExtFromFileNameParts.length - 1]
        : deriveExtFromContentType(file.type);

    if (!ext) {
      throw new Error('Unknown file ext');
    }

    return {
      ext,
      contentType: file.type,
    };
  });

  const presignedUrls = await getPresignedUrls(fileMetas);

  return presignedUrls.map((presignedUrl, idx) => ({
    fields: presignedUrl.fields,
    url: presignedUrl.url,
    key: presignedUrl.key,
    file: files[idx],
  }));
};

export const uploadImagesToS3 = async (
  preSignedUrls: PresignedUrlWithFile[]
): Promise<void> => {
  await Promise.all(
    preSignedUrls.map(async (presigned) => {
      const { fields, url, key, file } = presigned;
      const formData = new FormData();

      Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
        formData.append(key, value as string);
      });

      formData.append('key', key);
      formData.append('Content-Type', file.type);
      formData.append('file', file);

      await fetch(url, {
        method: 'POST',
        body: formData,
      });
    })
  );
};

/**
 * Presigned POST uploader
 */
export const createPresignedUrlsAndUploadToS3 = async ({
  files,
  getPresignedUrls,
}: CreatePresignedUrlProps): Promise<PresignedUrlResultItem[]> => {
  const preSignedUrls = await createPresignedUrls({ files, getPresignedUrls });

  await uploadImagesToS3(preSignedUrls);

  return preSignedUrls.map((presigned) => ({ key: presigned.key }));
};
