import { gql } from '@apollo/client';

export const GET_ALL_HASHTAGS = gql`
  query GetAllHashtags($input: AllHashtagsFilterInput!) {
    getAllHashtags(input: $input) {
      entities {
        id
        isGeneral
        name
        storeCount
      }
      limit
      offset
      total
    }
  }
`;
